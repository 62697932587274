import React, { useContext, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { GAContext } from "../../../GAContext";
import { AppContext } from "../../../store/store";
import Container from "../../Container/Container";
import Auth from "../../auth/Auth/Auth";
import SearchDropdown from "../../search/SearchDropdown";
import Sidebar from "../Sidebar/Sidebar";
import UserMenu from "../UserMenu/UserMenu";
import styles from "./Navigation.module.scss";
import { DarkModeContext } from "../../../utils/darkMode";

function Navigation(props) {
  const ref = useRef(null);
  const iconRef = useRef(null);
  const inputRef = useRef(null); 
  const { darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !iconRef.current.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        setIsNavOpened(false);
      }
      if (event.target.tagName == "P") setIsNavOpened(false);
    };

    const handleKeyPressOutside = (event) => {
      if (
        event.key === "Enter" &&
        ref.current &&
        ref.current.contains(event.target)
      ) {
        setIsNavOpened(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    document.addEventListener("keypress", handleKeyPressOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const { userProfile } = React.useContext(AppContext);
  const ReactGAContext = useContext(GAContext);

  const [isNavOpened, setIsNavOpened] = useState(false);

  const getAuthButtons = () => {
    if (!userProfile || !userProfile["access"]) {
      return <Auth />;
    } else {
      return (
        <UserMenu
          triggerGetNofitication={() => props.triggerGetNofitication()}
          user={userProfile}
        />
      );
    }
  };

  const toggleNavMenu = () => {
    setIsNavOpened((prevOpened) => !prevOpened);
  };

  const isMobile = useMediaQuery({ maxWidth: 1050 });

  const location = useLocation().pathname;

  React.useEffect(() => {
    ReactGAContext.pageview(location + window.location.search);
  }, [location]);

  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const mobileSearchButtonClicked = () => {
    setSearchButtonClicked((prev) => !prev);
  };

  const dropdownRef = useRef(null); // Reference to the dropdown

  
  
  useEffect(() => {
    if (searchButtonClicked && inputRef.current) {
      console.log(inputRef.current); // Debugging line

      inputRef.current.focus(); // Focus the input when the dropdown is shown
    }
  }, [searchButtonClicked]); // Run this effect when searchButtonClicked changes

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      mobileSearchButtonClicked();
    }
  };

  useEffect(() => {
    if (searchButtonClicked) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside); // For mobile
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [searchButtonClicked]);

  return (
    <>
      <nav
        className={styles.nav}
        style={{ backgroundColor: darkMode ? "#2c2c38" : "" }}
      >
        <Container className={styles.navContainer}>
          <div className={styles.navMenu}>
            <Link className={styles.logo} to="/">
              <span style={{ marginRight: "1.5px" }}>Politix</span>
              <span style={{ fontSize: "33%" }}>Beta</span>
            </Link>
            {isMobile && (
              <button
                className={styles.mobileSearchButton}
                onClick={mobileSearchButtonClicked}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="10" cy="10" r="7"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>{" "}
              </button>
            )}
            {!isMobile && <SearchDropdown />}
            {isMobile && !searchButtonClicked && (
              <div
                ref={ref}
                className={`${styles.mobileMenu} ${
                  isNavOpened && styles.mobileMenuOpen
                }`}
              >
                <Sidebar setIsNavOpened={setIsNavOpened}/>
              </div>
            )}
          </div>
          {isMobile && !searchButtonClicked && (
            <div className={`${styles.btnWrapper} ${styles.btnWrapperMobile}`}>
              {getAuthButtons()}
              <i
                ref={iconRef}
                onClick={toggleNavMenu}
                className={`fas fa-bars ${styles.closeIcon}`}
              />
            </div>
          )}
          {!isMobile && !searchButtonClicked && (
            <>
              <div className={styles.btnWrapper}>{getAuthButtons()}</div>
            </>
          )}
        </Container>
        {isMobile && searchButtonClicked && (
          <div ref={dropdownRef}>
            <SearchDropdown ref={inputRef} />
          </div>
        )}
      </nav>
      {/* {isMobile && location === "/" && (
        <NavLink className={styles.contentAlignCenter} to="/discover">
          <span className={styles.activeBtn}>Discover More</span>
        </NavLink>
      )} */}
    </>
  );
}

export default Navigation;
