import React from "react";
import styles from "./Sidebar.module.scss"; // Import the SCSS module
// import HomeIcon from '@mui/icons-material'

// import CentralItem1 from "./CentralItem1";
// import CentralItem2 from "./CentralItem2";
// import CentralItem3 from "./CentralItem3";
import { useContext } from "react";
import LogoutIcon from "@mui/icons-material/ExitToApp";

import { Link, useNavigate, useLocation } from "react-router-dom";
// import FeaturedSection from "./FeaturedSectionV3";
// import Discover from "../Discover/Discover"
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExploreIcon from "@mui/icons-material/Explore";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import VideocamIcon from "@mui/icons-material/Videocam";
import { AppContext } from "../../../store/store";
import Auth from "../../auth/Auth/Auth";
import { ACTIONS } from "../../../store/reducer";
import ReactGA from "react-ga";
import AuthService from "../../../services/auth.service";


const Sidebar = ({setIsNavOpened}) => {
  const { dispatch } = useContext(AppContext);
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const { userProfile } = React.useContext(AppContext);
  const [streamPerms, setStreamPerms] = React.useState(false);
  const [channelID, setChannelID] = React.useState(null);
  const [liveChannelsList, setLiveChannelsList] = React.useState([]);
  const location = useLocation();
  const onLogout = () => {
    dispatch({ type: ACTIONS.SET_USER, payload: {} });
    ReactGA.event({
      category: "User",
      action: "Logged Out",
    });
    AuthService.logout();
    if (
      location["pathname"].includes("profile") ||
      location["pathname"].includes("streamer")
    ) {
      history("/");
    }
  };

  const canStream = () => {
    fetch(`https://politix.tv/api/users/streamer/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStreamPerms(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const getUserData = (access) => {
    fetch(`https://politix.tv/api/users/me/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`, // ${TOKEN_VALUE}
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setChannelID(data.channel_id);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const getLiveChannels = (access) => {
    fetch(`https://politix.tv/api/livestream/content/live `, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLiveChannelsList(data);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };
  React.useEffect(() => {
    getLiveChannels();
  }, []);

  React.useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, [user?.access]);

  React.useEffect(() => {
    if (user?.access) {
      getUserData(user.access);
    }
  }, [user?.access]);
  //should only do it once
  React.useEffect(() => {
    if (user?.access) {
      canStream(user.access);
    }
  }, [user?.access]);

  // React.useEffect(() => {
  //   const user = AuthService.getCurrentUser();
  //   if (user && Object.keys(user).length > 0) {
  //     AuthService.verifyAuthToken()
  //   }
  // });
  const history = useNavigate();
  const handleSidebarItemClick = (route) => {
    history(route);
    setIsNavOpened(false);
  };

  return (
    <>
      <div className={styles.containerPadding}>
        <div className={styles.sidebar}>
          {(!userProfile || !userProfile["access"]) && (
            <div className={styles.login}>
              <Auth disableJoinBtn="true" />
            </div>
          )}
          {user && userProfile["access"] && (
            <>
              <div
                className={styles.sidebarTitle}
                onClick={() => handleSidebarItemClick("/profile/me/settings")}
                style={{ padding: "10px 0px 5px 5px" }}
              >
                <AccountBoxIcon
                  style={{
                    color: "#012060",
                    marginRight: "5px",
                    paddingTop: "5px",
                  }}
                />
                {user["username"]}
              </div>
              {channelID && (
                <div
                  className={styles.sidebarItem}
                  onClick={() =>
                    handleSidebarItemClick(`/streamer/${channelID}`)
                  }
                >
                  Channel
                </div>
              )}
              {streamPerms && !channelID && (
                <div
                  className={styles.sidebarItem}
                  onClick={() => handleSidebarItemClick("/live/channel-list")}
                >
                  Channel
                </div>
              )}
              <div
                className={styles.sidebarItem}
                onClick={() => handleSidebarItemClick("Following")}
              >
                Following
              </div>

              <div
                className={styles.sidebarItem}
                onClick={() => handleSidebarItemClick("Subscriptions")}
              >
                Subscriptions
              </div>

              {/* <div
                className={styles.sidebarItem}
                onClick={() => handleSidebarItemClick("Friends")}
                style={{ paddingBottom: "10px" }}
              >
                Friends
              </div> */}
            </>
          )}

          <div
            className={styles.sidebarTitle}
            onClick={() => handleSidebarItemClick("/explore/")}
            style={{
              paddingTop: "16px",
              paddingBottom: "16px",
              borderBottom: "1px solid white",
              borderTop: "1px solid white",
            }}
          >
            <ExploreIcon
              style={{
                color: "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Explore
          </div>
          <div
            className={styles.sidebarTitle}
            onClick={() => handleSidebarItemClick("/")}
            style={{
              paddingTop: "16px",
              paddingBottom: "10px",
            }}
          >
            <VideocamIcon
              style={{
                color: "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            Live Now
          </div>
          {liveChannelsList.length > 0 && (
            <ol className={styles.liveNowStreamersList}>
              {liveChannelsList.map((streamer, index) => (
                <li
                  className={styles.liveNowStreamer}
                  onClick={() =>
                    handleSidebarItemClick(`/channel/${streamer.id}`)
                  }
                >
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/channel/${streamer.id}}`}
                  >
                    <img
                      src={streamer.avatar}
                      className={styles.channelLogo}
                      alt="logo"
                    />
                  </Link>
                  {streamer.name}
                </li>
              ))}
            </ol>
          )}

          <div
            className={styles.sidebarTitle}
            onClick={() => handleSidebarItemClick("/about-us")}
            style={{
              paddingTop: "16px",
              paddingBottom: "16px",
              borderTop: "1px solid white",
            }}
          >
            <InfoIcon
              style={{
                color: "#012060",
                marginRight: "5px",
                paddingTop: "5px",
              }}
            />
            About Us
          </div>
          {user && userProfile["access"] && (
            <div
              className={styles.sidebarTitle}
              onClick={() => handleSidebarItemClick("/profile/me/settings")}
              style={{}}
            >
              <SettingsIcon
                style={{
                  color: "#012060",
                  marginRight: "5px",
                  paddingTop: "5px",
                }}
              />
              Settings
            </div>
          )}
          {user && userProfile["access"] && (
            <div
              className={styles.sidebarTitle}
              onClick={onLogout}
              style={{
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              <LogoutIcon
                style={{
                  color: "#012060",
                  marginRight: "9px",
                  marginLeft: "3px",
                  fontSize: "large",
                }}
              />
              Log Out
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
