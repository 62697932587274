import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import PaymentService from "../../../sevices2/paymentServices";
import styles from "./SubscribeModal.module.scss";

const SubscribeModal = ({ pfp, channel, access, channelID, closeModal }) => {
  const [step, setStep] = useState(1);
  const [clientSecret, setClientSecret] = useState("");
  const stripePromise = loadStripe(
    String(process.env.REACT_APP_STRIPE_PROMISE)
  );
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  useEffect(() => {
    PaymentService.getCustomerPaymentDetails().then(
      (paymentDetailsResponse) => {
        setPaymentMethods(paymentDetailsResponse.details.data);
        setDefaultPaymentMethod(paymentDetailsResponse?.default_payment_method);
      }
    );
  }, []);

  const getClientSecret = () => {
    if(!isChecked){
      setClicked(true);
      return;
    }
    PaymentService.createSubscription(access, channelID)
      .then((data) => {
        return data;
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        if (data.clientSecret) {
          PaymentService.getCustomerPaymentDetails()
            .then((paymentDetailsResponse) => {
              setPaymentMethods(paymentDetailsResponse.details.data);
              setDefaultPaymentMethod(
                paymentDetailsResponse.default_payment_method
              );
              return paymentDetailsResponse;
            })
            .then((paymentDetailsResponse) => {
              if (paymentDetailsResponse.details.data.length > 0) {
                setStep(3);
              } else {
                setStep(2);
              }
            });
        } else {
          setStep(2);
        }
      });
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {step === 1 && (
        <div className={styles.blurBackground}>
          <div className={styles.container}>
            <div className={styles.header}>
              <button className={styles.closeModalButton} onClick={closeModal}>
                X
              </button>
              <h3 style={{ margin: "0 0 0 0", fontSize: "20pt" }}>
                Show Your Support
              </h3>
            </div>
            <img src={pfp} alt="channel-thumbnail" className={styles.pfp} />
            <h3 className={styles.channelName}>{channel}</h3>
            <div className={styles.gridContainer}>
              <h3 className={styles.spanTwoColumns}>
                Exclusive Supporter Benefits
              </h3>
              <div className={styles.gridItem}>
                <div className={styles.exclusive}></div>
                <p className={styles.descriptions}>Exclusive Content</p>
              </div>
              <div className={styles.gridItem}>
                <div className={styles.support}></div>
                <p className={styles.descriptions}>Support the Creator</p>
              </div>
              <div className={styles.gridItem}>
                <div className={styles.badge}></div>
                <p className={styles.descriptions}>Subscriber Badge</p>
              </div>
              <div className={styles.gridItem}>
                <div className={styles.chatBubble}></div>
                <p className={styles.descriptions}>Chat During Sub Mode</p>
              </div>
            </div>
            <h3 className={styles.subscriptionTier}>Tier 1 Subscription</h3>
            <h3 className={styles.subscriptionPrice}>Price Per Month: $5.99</h3>
            <h3 className={styles.cancel}>Cancel Anytime in Settings</h3>
            <div className={styles.row}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <h3 className={styles.cancel}>I agree</h3>
              {!isChecked && clicked && (
                <h3 className={styles.cancel} style={{ color: "#b66769", marginLeft: '20px' }}>
                  Please Check the Box
                </h3>
              )}
            </div>
            <button
              className={styles.subscribeButton}
              onClick={() => getClientSecret()}
            >
              Checkout
            </button>
            <p className={styles.footer}>
              <span style={{ color: "red" }}>&#x2764;</span> Subscriptions
              support streamers
            </p>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className={styles.blurBackground}>
          <div
            className={styles.container}
            style={{ width: "650px", height: "480px" }}
          >
            <div className={styles.header}>
              <button
                className={styles.closeModalButton}
                style={{ marginLeft: "600px" }}
                onClick={closeModal}
              >
                X
              </button>
              <h3 style={{ margin: "0 0 0 0", fontSize: "20pt" }}>
                Complete Your Purchase
              </h3>
            </div>
            <img src={pfp} alt="channel-thumbnail" className={styles.pfp} />
            <h3 className={styles.channelName}>{channel}</h3>
            {/* <div className={styles.stepTwoGrid}>
              <div className={styles.trophy}></div>
              <p className={styles.descriptions}>Supporter Tier Subscription</p>
              <div className={styles.calendar}></div>
              <p className={styles.descriptions}>$4.99 Billed Monthly</p>
              <div className={styles.heart}></div>
              <p className={styles.descriptions}>Directly Supports {channel}</p>
            </div> */}
            <div style={{ width: "600px" }}>
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <PaymentService.CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className={styles.blurBackground}>
          <div
            className={styles.container}
            style={{ width: "400px", height: "550px" }}
          >
            <div className={styles.header}>
              <button
                className={styles.closeModalButton}
                style={{ marginLeft: "360px" }}
                onClick={closeModal}
              >
                X
              </button>
              <h3 style={{ margin: "0 0 0 0", fontSize: "20pt" }}>
                Complete Your Purchase
              </h3>
            </div>
            <img src={pfp} alt="channel-thumbnail" className={styles.pfp} />
            <h3 className={styles.channelName}>{channel}</h3>
            <div className={styles.stepTwoGrid}>
              <div className={styles.trophy}></div>
              <p className={styles.descriptions}>Supporter Tier Subscription</p>
              <div className={styles.calendar}></div>
              <p className={styles.descriptions}>$4.99 Billed Monthly</p>
              <div className={styles.heart}></div>
              <p className={styles.descriptions}>Directly Supports {channel}</p>
            </div>

            <div style={{ width: "600px" }}></div>
            <p className={styles.descriptions}>Select A Payment Method</p>
            <div className={styles.buttonContainer}>
              {paymentMethods.map((payment, index) => (
                <button
                  key={index}
                  onClick={() => setDefaultPaymentMethod()}
                  className={styles.subscribeButton}
                >
                  {payment.card.brand} ending in {payment.card.last4}
                </button>
              ))}
            </div>
            <button
              style={{ width: "200px" }}
              className={styles.subscribeButton}
              onClick={() => setStep(2)}
            >
              New Payment Method
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribeModal;
